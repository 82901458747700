import PageWrapper from "../../components/PageWrapper";
import {Col, Container, Row} from "react-bootstrap";
import {Box, Title} from "../../components/Core";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import React from "react";
import { Helmet } from "react-helmet"
import styled from "styled-components";
import Img from 'gatsby-image';
import CTA from './CTA';

const defaultPage = {
    heading: '',
    subheading:  '',
    pageName: '',
    bodyText: {
        json: {}
    }
}

const BoxStyled = styled(Box)`

   margin-top: 100px;
   margin-bottom: 5%;
  .block-title {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
  }
   
  .services-2 h5 {
    color: black;
    margin: 1.5rem 0rem;
  }
  ul li {
    display: inline;
    color: black;
    list-style: outside;
  }
  ul {
    color: black;
    list-style: outside;
  }
  .services ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .services-2 ul {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
    
  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;

const ImgContainer = styled(Box)`
  /* border-radius: 10px; */
  overflow: hidden;

`;

const StandardPage = (props) => {
    const page = (props.data.data && props.data.data.contentfulStandardPage) ? props.data.data.contentfulStandardPage : defaultPage
    let imgPadding = "40%"
    if(props.imgPadding){
        imgPadding = props.imgPadding
    }
    return (
        <>
            <Helmet>
                <title>{page.pageName}</title>
            </Helmet>
            <PageWrapper footerDark>
                <BoxStyled>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="6">
                                <ImgContainer className="text-center">
                                    <Img alt="" className="img-fluid" fluid={page.pageImage.fluid} style={{paddingBottom: imgPadding}} />
                                </ImgContainer>
                            </Col>
                            <Col lg="6" className="my-4 mb-lg-5">
                                <Box className="justify-content-center text-center">
                                    <Title>{page.heading}</Title>
                                </Box>
                                <Box className="justify-content-center mb-5 text-center">
                                    <div className="mb-5">
                                        {page.subheading && (
                                            <h1 className="block-title mb-4">
                                                {page.subheading}
                                            </h1>
                                        )}
                                    </div>
                                </Box>
                                <Box className="justify-content-center services text-center">
                                    {documentToReactComponents(page.bodyText.json)}
                                </Box>
                            </Col>
                            <Col lg="12">
                                <div>
                                    {page.twoColumns && (
                                        <div className="pt-5 services-2">
                                            {documentToReactComponents(page.secondColumnBodyText.json)}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </BoxStyled>
                <CTA />
            </PageWrapper>
        </>
    );
}
export default StandardPage;
