import React from "react";
import StandardPage from "../sections/standardPage/StandardPage";
import { graphql } from 'gatsby'

const CareersPage = (data) =>
    <StandardPage data={data}/>

export default CareersPage;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 8}) {
            heading
            subheading
            pageName
            pageId
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
